import React, { useState } from "react"
import FaqItem from "./FaqItem"

interface FaqListProps {
  data?: any
}

const FaqList = ({ data }: FaqListProps) => {
  const [open, setIsOpen] = useState(Array())
  const checkOpen = (index) => {
    return open.includes(index) ? true : false
  }

  const toggle = (index) => {
    if (open.includes(index)) {
      setIsOpen(open.filter((item) => item !== index))
    } else {
      setIsOpen((old) => [...old, index])
    }
  }

  const qaItem = data.allMarkdownRemark.edges.map((node, index) => {
    const isOpen = checkOpen(index)
    return (
      <FaqItem
        item={node}
        key={index}
        mykey={index}
        isOpen={isOpen}
        onClick={() => {
          checkOpen(index)
          toggle(index)
        }}
      />
    )
  })
  return (
    <div className="container1">
      <dl className="container2">{qaItem}</dl>
    </div>
  )
}

export default FaqList
