import React from "react"
import { ChevronDown } from "../Resources/svg/chevronDown"
import { ChevronForward } from "../Resources/svg/chevronForward"

interface RenderArrowProps {
  isOpen: boolean
  title: string
  mykey: number
  onClick: any
}

const RenderArrow = ({ isOpen, title, mykey, onClick }: RenderArrowProps) => {
  return (
    <button
      className={"faq_question-button"}
      aria-controls={`faq${mykey + 1}_id`}
      aria-expanded={`${isOpen ? "true" : "false"}`}
      onClick={onClick}
    >
      {title}
      {isOpen ? <ChevronDown /> : <ChevronForward />}
    </button>
  )
}

export default RenderArrow
