import { Link } from "gatsby"
import React from "react"
import { IoArrowForward } from "@react-icons/all-files/io5/IoArrowForward"
import styled from "styled-components"

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3d3c3c;
  font-family: "AmazonEmberMedium";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 15px;
`

interface RenderAnswerProps {
  isOpen: boolean
  description: string
  link: string
  slug: string
  mykey: number
}

const RenderAnswer = ({
  isOpen,
  description,
  link,
  slug,
  mykey,
}: RenderAnswerProps) => {
  return (
    <div
      id={`faq${mykey + 1}_id`}
      className={`faq_answer ${isOpen ? "" : "hidden"}`}
    >
      <p dangerouslySetInnerHTML={{ __html: description }} />
      {link == null ? (
        ""
      ) : (
        <StyledLink to={`${slug}`}>
          {link}
          <IoArrowForward className={"rightArrow"} />
        </StyledLink>
      )}
    </div>
  )
}

export default RenderAnswer
