import React, { FC } from "react"
import "./FaqItem.css"
import RenderAnswer from "./renderAnswer"
import RenderArrow from "./renderArrow"

interface FaqItemProps {
  isOpen: boolean
  item: any
  mykey: number
  onClick: any
}

const FaqItem = ({ isOpen, item, mykey, onClick }: FaqItemProps) => (
  <div className="faq_question" key={mykey}>
    <dt>
      <RenderArrow
        isOpen={isOpen}
        title={item.node.frontmatter.title}
        mykey={mykey}
        onClick={onClick}
      />
    </dt>
    <dd>
      <RenderAnswer
        isOpen={isOpen}
        description={item.node.frontmatter.description}
        link={item.node.frontmatter.link}
        slug={item.node.fields.slug}
        mykey={mykey}
      />
    </dd>
  </div>
)

export default FaqItem
