import React from "react"
import Layout from "../components/layout"
import Faq from "../components/FaqList"
import { graphql } from "gatsby"
import Header from "../components/header"

const answerPage = ({ data }) => {
  return (
    <Layout>
      <Header
        siteTitle={data.allMarkdownRemark.edges[0].node.frontmatter.category}
      />
      <Faq data={data} />
    </Layout>
  )
}

export default answerPage

export const query = graphql`
  query ($category: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            title
            date
            description
          }
          html
        }
      }
    }
  }
`
